<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return"><el-button type="text" size="small"
						@click="config.isDetailDisplay = false">返回</el-button> </p>
				<div class="h-b"><el-button type="primary" size="small" @click="editApi"
						v-if="!config.isAdd && config.isCanEdit" v-right-code="'Exportjobinfo:Edit'">编辑</el-button>
				</div>
				<div class="h-b"><el-button type="primary" size="small" @click="saveApi" v-if="config.isAdd"
						v-right-code="'Exportjobinfo:Edit'">保存</el-button>
				</div>
			</div>
		</div>

		<el-tabs type="border-card" v-model="activeName">
			<el-tab-pane name="tabbasic">
				<span slot="label">基本信息</span>
				<div class="form-list">
					<el-form :model="dataSource" ref="_editForm" :rules="validateRule">
						<el-form-item>
							<el-col :span="3" class="form-title">
								<span style="color:red;">*</span>任务名称 ：
							</el-col>
							<el-col :span="8">
								<el-form-item prop="Code">
									<el-input v-model="dataSource.Name" :maxlength="100" :minlength="1"
										placeholder="任务名称" :disabled="!config.isAdd"></el-input>
								</el-form-item>
							</el-col>

						</el-form-item>

						<el-form-item>
							<el-col :span="3" class="form-title"><span style="color:red;">*</span>周期类型：
							</el-col>
							<el-col :span="8">
								<el-form-item prop="CronValue">
									<!-- <el-input v-model="dataSource.CronValue" :maxlength="100" :minlength="1"
										placeholder="周期类型" :disabled="!config.isAdd" readonly="">
										<el-button slot="append" icon="el-icon-edit"
											@click="cronPopVisible = !cronPopVisible"></el-button>
									</el-input> -->
									<JobCycleSelect :cycleTypeSelect="dataSource" :disabled="!config.isAdd"></JobCycleSelect>
								</el-form-item>
							</el-col>
							<!-- <el-col :span="8">
								<el-form-item>
									<el-popover placement="right" width="500" trigger="click" v-model="cronPopVisible">
									<vcrontab @hide="cronPopVisible = false" @fill="crontabFill"
										:expression="dataSource.CronValue"></vcrontab>
								</el-popover>
								</el-form-item>
							</el-col> -->

						</el-form-item>

						<el-form-item>
							<el-col :span="3" class="form-title">
								<span style="color:red;">*</span>状态：
							</el-col>
							<el-col :span="8">
								<el-form-item prop="Status">
									<DictionarySelect v-model="dataSource.Status" datType="Int"
										dicTypeCode="CommonStatusCode" :disabled="!config.isAdd">
									</DictionarySelect>
								</el-form-item>
							</el-col>
						</el-form-item>

						<el-form-item>
							<el-col :span="3" class="form-title">数据源：
							</el-col>
							<el-col :span="5">
								<el-form-item prop="TenantSystemName">
									<LookPopup v-model="dataSource.TenantSystemName" dataTitle="数据源"
										:dataUrl=tenantSystmeAction syncField='TenantSystemName'
										:disabled="!config.isAdd" @syncFieldEvent="syncMiddlewareSystem"></LookPopup>
								</el-form-item>
							</el-col>

						</el-form-item>
						<el-form-item>
							<el-col :span="3" class="form-title">单据类型：
							</el-col>
							<el-col :span="8">
								<el-form-item prop="ExportDataType">
									<DictionarySelect v-model="dataSource.ExportDataType"
										dicTypeCode="ExportDataTypeCode" datType="Int" :disabled="!config.isAdd">
									</DictionarySelect>
								</el-form-item>
							</el-col>
						</el-form-item>

						<el-form-item>
							<el-col :span="3" class="form-title">导出Sql：
							</el-col>
							<el-col :span="21">
								<el-form-item prop="ExportSql">
									<el-input type="textarea" rows="26" v-model="dataSource.ExportSql"
										:disabled="!config.isAdd">
									</el-input>
								</el-form-item>
							</el-col>
						</el-form-item>

					</el-form>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>
<script>

import vcrontab from "vcrontab";
import JobCycleSelect from '../../../../common/jobcycle/jobcycleselect.vue'
export default {
	mounted() { },
	data() {
		return {
			tenantSystmeAction: 'omsapi/exportjobinfo/gettenantsystemtlist',
			cronPopVisible: false,
			activeName: "tabbasic",
			formItems: ["formItem"],
			validateRule: {
				Name: [{
					required: true,
					message: '请输入任务名称',
					trigger: 'blur'
				}],
				Status: [{
					required: true,
					message: '请选择状态',
					trigger: 'blur'
				}],
				CronValue:[{
					required: true,
					message: '请选择周期类型',
					trigger: 'blur'
				}],
			},
			tableData: [

			],
			dataSource: {

			},
		};
	},
	props: {
		config: {
			isDetailDisplay: false,
			dataSourceKey: null,
		},
	},
	watch: {
		'config.dataSourceKey': {
			handler(curVal, oldVal) {
				if (curVal && curVal > 0) {
					this.getDetail(curVal); //初始化
				} else {
					if (curVal == null) {
						this.dataSource = {};
					}

				}
			},
			deep: true
		},
	},
	methods: {
		add() {
			var _this = this;
			this.$ajax.send("omsapi/exportjobinfo/getempty", "get", {}, (data) => {
				_this.dataSource = data.Result;

			});
		},
		crontabFill(val) {
			console.log(val)
			this.dataSource.CronValue = val;
		},
		editApi() {
			this.config.isAdd = true;
		},
		detailApi(apiId) {
			this.config.isAdd = false;
		},
		getDetail(apiId) {
			var _this = this;
			this.$ajax.send("omsapi/exportjobinfo/get", "get", {
				id: apiId
			}, (data) => {
				_this.dataSource = data.Result;
			});
		},
		syncMiddlewareSystem(row) {
			if (row) {
				this.dataSource.TenantSystemId = row.Id;
				this.dataSource.TenantSystemName = row.TenantSystemName;
			}
		},
		saveApi() {
			this.$refs["_editForm"].validate((valid) => {
				if (valid) {
					var actionName = this.dataSource.Id && this.dataSource.Id > 0 ? 'edit' : 'create';
					var obj = this.Utils.cloneObj(this.dataSource);
					this.$ajax.send("omsapi/exportjobinfo/" + actionName, "post", obj, (data) => {
						this.config.dataSourceKey = data.Result;
						this.config.isCanEdit = false;
						this.config.isAdd = false;
						//this.detailApi(curVal)
						this.Event.$emit("reloadRolePageList");
						this.Utils.messageBox("保存成功.", "success");
					});
				} else {
					this.Utils.messageBox("存在必填信息未输入.", "error");
					return false;
				}
			});
		},
	},
	components: { vcrontab ,JobCycleSelect}
}
</script>