<template>
    <div>
        <el-col :span="avgSpans" class="form-title">
            <DictionarySelect v-model="cycleTypeSelect.CycleType" datType="Int" @change="typeChange"
                dicTypeCode="JobCycleTypeCode" :readonly='readonly' :disabled='disabled'></DictionarySelect>
        </el-col>
        <el-col :span="avgSpans" class="form-title">
            <el-select v-model="cycleTypeSelect.CycleDay" :readonly='readonly'
                :disabled='disabled' @change="cronChange">
                <el-option v-for="item in dayList" :key="item" :label="item" :value="item" :disabled="disabled">
                </el-option>
            </el-select></el-col>

        <el-col :span="avgSpans" class="form-title">
            <el-time-picker v-model.trim="cycleTypeSelect.CycleTime" size="mini" placeholder="选择时间" format="HH:mm"
                value-format="HH:mm" @change="cronChange"></el-time-picker>
        </el-col>

    </div>
</template>
<script>


export default {
    name: "JobCycleSelect",
    model: { prop: 'cycleType' },
    data() {
        return {
            dayList: ['每天'],
        };
    },
    props: {
        readonly: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        cycleTypeSelect: {
            type: Object,
            default: {
                CronValue: '',
                CycleType: 1,
                CycleDay: '',
                CycleTime: '',
            },
        },
    },
    computed:{
        avgSpans(){
            return 8;
        }
    },
    mounted() { },
    methods: {
        typeChange(val) {
            if (val === 1) {
                //day
                this.dayList = ['每天'];
                this.cycleTypeSelect.CycleDay = this.dayList[0];
            } else if (val === 2) {
                //week
                this.dayList = [...Array(7)].map((_, index) => index + 1 + "");
                this.cycleTypeSelect.CycleDay = this.dayList[0];
            } else {
                //month
                this.dayList = [...Array(31)].map((_, index) => index + 1 + "");
                this.cycleTypeSelect.CycleDay = this.dayList[0];
            }
            this.cronChange();
        },
        cronChange(){
            if(!this.cycleTypeSelect.CycleTime){
                return;
            }
            let minute = this.cycleTypeSelect.CycleTime.split(':')[1];
            let hour = this.cycleTypeSelect.CycleTime.split(':')[0];
            let cronVal = '00 00 * * *';
            if(this.cycleTypeSelect.CycleType === 1){
                //day 0 0 12 * * ?
                cronVal = '0 '+minute+' '+hour+' * * ?';
            }else if(this.cycleTypeSelect.CycleType === 2){
                //week 0 00 14 ? * 1
                cronVal = '0 '+minute+' '+hour+' ? * '+this.cycleTypeSelect.CycleDay;
            }else{
                //month 0 0 14 4 * ?
                cronVal = '0 '+minute+' '+hour+' '+this.cycleTypeSelect.CycleDay+' * ? ';
            }
            this.cycleTypeSelect.CronValue = cronVal;
            console.log(this.cycleTypeSelect);
        },
    },
}
</script>
